import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { withOrientationChange } from "react-device-detect";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  TitleWrapper,
  TabletTitleWrapper,
  Title,
  Subtitle,
  ImageWrapper,
  WhiteBorderLeft,
  WhiteBorderRight,
  LogoWrapper,
  Logo
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import ImageSlider from "../../../../../common/ImageSlider";
import { PageTitle } from "../../../../../common/PageTitle";
import { device } from "../../../../../../utils/breakpoints";


interface JoinOurTeamProps {
  pageChange: PageChange;
  showFooterOnTop?: (show: boolean) => void;
  isLandscape?: boolean;
}

const JoinOurTeam: React.FC<JoinOurTeamProps> = ({
  pageChange,
  showFooterOnTop,
  isLandscape,
}) => {
  const { t } = useTranslation("career");
  const data = useStaticQuery(imagesQuery);
  const [open, setOpen] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(true);

 
  useEffect(() => {
    setTimeout(() => {
      showFooterOnTop && showFooterOnTop(true);
    });
    setTimeout(() => {
      setOpen(false);
    }, 5000);

    return () => showFooterOnTop && showFooterOnTop(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
    }, 5000)
  }, [device])

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`joinOurTeam_subtitle`))} />
      <PageWrapper animation={pageChange}>
      <TabletTitleWrapper>
          <Title>{fixConjunctions(t(`joinOurTeam_title`))}</Title>
          <Subtitle>{fixConjunctions(t(`joinOurTeam_subtitle`))}</Subtitle>
      </TabletTitleWrapper>
       {visible && 
        <TitleWrapper>
            <Title>{fixConjunctions(t(`joinOurTeam_title`))}</Title>
            <Subtitle>{fixConjunctions(t(`joinOurTeam_subtitle`))}</Subtitle>
          </TitleWrapper>
        }
        <ImageWrapper>
          <ImageSlider
            images={data && data.allFile.nodes}
            imageFitStyle={"cover"}
          />
        </ImageWrapper>
        <WhiteBorderLeft />
        <WhiteBorderRight />
        <LogoWrapper>
          <Logo />
          {fixConjunctions(t(`joinOurTeam_copyright`))}
        </LogoWrapper>
      </PageWrapper>
    </>
  );
};

const imagesQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "joinOurTeam" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxHeight: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default withOrientationChange(JoinOurTeam);
