import styled, { css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import DesktopLogo from "-!svg-react-loader!../../../../../../assets/images/Logo/desktopLogo.svg";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const TitleWrapper = styled.div`
  display: none;

  @media ${device.computer} {
    display: inline-block;
    background: #013cff;
    animation-fill-mode: forwards;
    margin-top: 128px;
    padding: 60px 80px 60px 120px;
    animation: none;
  } ;
`;

export const TabletTitleWrapper = styled.div`
  @media ${device.tablet} {
    display: inline-block;
    width: 592px;
    height: 138px;
    padding: 32px 0 32px 88px;
    margin-top: 94px;
    animation-fill-mode: forwards;
    background: #013cff;
  }

  @media ${device.tabletLandscape} {
    display: inline-block;
    width: 592px;
    height: 138px;
    padding: 32px 0 32px 88px;
    margin-top: 94px;
    animation-fill-mode: forwards;
    background: #013cff;
  }

  @media ${device.computer} {
    display: none;
  } ;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  line-height: 37.12px;
  font-weight: 700;
  color: #ffffff;

  @media ${device.computer} {
    font-size: 36px;
    line-height: 41.76px;
  }
`;

export const Subtitle = styled.h2`
  margin: 0;
  font-size: 32px;
  line-height: 37.12px;
  font-weight: 700;
  color: #151515;

  @media ${device.computer} {
    font-size: 36px;
    line-height: 41.76px;
  }
`;

export const ImageWrapper = styled.div`
  @media ${device.tablet} {
    position: absolute;
    top: 36%;
    left: 88px;
    width: calc(100vw - 278px);
    height: 27%;
    z-index: -1;
  }

  @media ${device.tabletLandscape} {
    position: absolute;
    top: 163px;
    left: 88px;
    width: calc(100vw - 278px);
    height: 440px;
    z-index: -1;
  }

  @media ${device.computer} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const WhiteBorderLeft = styled.div`
  @media ${device.tablet} {
    position: absolute;
    width: 88px;
    height: 38%;
    left: 0;
    top: 35%;
    background: white;
  }

  @media ${device.tabletLandscape} {
    position: absolute;
    top: 160px;
    left: 0;
    width: 88px;
    height: 510px;
    background: white;
    z-index: -1;
  } ;
`;

export const WhiteBorderRight = styled.div`
  @media ${device.tablet} {
    position: absolute;
    width: calc(100vw - calc(100vw - 278px) - 88px);
    height: 38%;
    right: 0;
    top: 35%;
    z-index: 1;
    background: white;
  }

  @media ${device.tabletLandscape} {
    position: absolute;
    width: calc(100vw - calc(100vw - 278px) - 88px);
    top: 160px;
    right: 0;
    height: 510px;
    z-index: -1;
    background: white;
  } ;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 88px;
  bottom: 68px;
  color: #0500ff;
  font-size: 16px;
  line-height: 19.5px;
  letter-spacing: 1px;

  @media ${device.tabletLandscape} {
    bottom: 48px;
  }

  @media ${device.computer} {
    left: 120px;
    bottom: 80px;
    color: white;
  } ;
`;

export const Logo = styled(DesktopLogo)`
  margin-bottom: 8px;
  width: 330px;

  path {
    fill: #0500ff !important;
  }

  @media ${device.computer} {
    path {
      fill: #ffffff !important;
    }
  } ;
`;
